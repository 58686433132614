import { Suspense } from "react";
import Sidebar from "../../components/sidebar";
import { HocPrivate } from "./hoc";
import { PageContainer, WrapLoader } from "./style";
import { Loader } from "@mantine/core";
import { Outlet } from "react-router-dom";
import { Container } from "./style";
import Header from "../../components/header";
import { useDisclosure } from "@mantine/hooks";

const PrivateRouter = () => {
  const [opened, { toggle }] = useDisclosure(false);
  const label = opened ? "Close navigation" : "Open navigation";

  return (
    <Container>
      <Sidebar opened={opened} toggle={toggle} label={label} />

      <PageContainer>
        <Header opened={opened} toggle={toggle} label={label} />
        <HocPrivate>
          <Suspense
            fallback={
              <WrapLoader>
                <Loader variant="bars" />
              </WrapLoader>
            }
          >
            <Outlet />
          </Suspense>
        </HocPrivate>
      </PageContainer>
    </Container>
  );
};

export default PrivateRouter;

// import { ROUTER_PATH } from '@/constants/router-paths'
// import { ReactComponent as IconCreate } from '@/icons/create.svg'

import { ROUTER_PATH } from "./router-paths";
import {
  IconTruckDelivery,
  IconLocation,
  IconShoppingBag,
  IconMoneybag,
  IconBuilding,
  IconClock,
  IconMessage,
  IconUserQuestion,
  IconShoppingBagDiscount,
} from "@tabler/icons-react";

export const SIDEBAR = [
  {
    label: "Mahsulotlar",
    link: ROUTER_PATH.PRODUCTS,
    icon: IconShoppingBag,
  },
  {
    label: "Partnerlar",
    link: ROUTER_PATH.PARTNERS,
    icon: IconMoneybag,
  },
  {
    label: "Obyektlar",
    link: ROUTER_PATH.OBJECTS,
    icon: IconBuilding,
  },
  {
    label: "Izohlar",
    link: ROUTER_PATH.REVIEW,
    icon: IconMessage,
  },
  {
    label: "Bildirishnomalar",
    link: ROUTER_PATH.QUESIONS,
    icon: IconClock,
  },
  {
    label: "Optom sotib olish",
    link: ROUTER_PATH.BUYWHOLE,
    icon: IconShoppingBagDiscount,
  },

  {
    label: "Buyurtmalar",
    link: ROUTER_PATH.ORDERS,
    icon: IconTruckDelivery,
  },
  {
    label: "Filiallar",
    link: ROUTER_PATH.BRANCHS,
    icon: IconLocation,
  },
];

import axios, { type AxiosHeaders } from "axios";

const baseURL = process.env.REACT_APP_API_BASE_URL;

const request = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

request.interceptors.request.use(async (config) => {
  if (localStorage.getItem("finfire-admin-token")) {
    // if (user.isExpiredRefresh) {
    //   clearUser();
    //   window.location.href = "/";
    //   return config;
    // }

    // clearStorage
    if (localStorage.getItem("finfire-admin-token")) {
      (config.headers as AxiosHeaders).set(
        "Authorization",
        `Bearer ${localStorage.getItem("finfire-admin-token")}`
      );
      return config;
    }
    // await refreshToken();
    return config;
  }
  return config;
});

request.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export { request };

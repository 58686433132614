import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import { ROUTER_PATH } from "../constants/router-paths";
import PrivateRouter from "./private/private";

const Login = lazy(async () => await import("../pages/login/page"));
const ProductsCrud = lazy(
  async () => await import("../pages/products/crud/page")
);
const Products = lazy(async () => await import("../pages/products/page"));
const Objects = lazy(async () => await import("../pages/objects/page"));
const Partners = lazy(async () => await import("../pages/partners/page"));
const Review = lazy(async () => await import("../pages/review/page"));
const Question = lazy(async () => await import("../pages/question/page"));
const BuyWhole = lazy(async () => await import("../pages/buywhole/page"));
const Branches = lazy(async () => await import("../pages/branches/page"));
const Orders = lazy(async () => await import("../pages/order/page"));
const BranchsCrud = lazy(
  async () => await import("../pages/branches/crud/page")
);
const PartnersCrud = lazy(
  async () => await import("../pages/partners/crud/page")
);
const ObjectsCrud = lazy(
  async () => await import("../pages/objects/crud/page")
);

export const router = createBrowserRouter([
  {
    path: ROUTER_PATH.LOGIN,
    element: <Login />,
  },

  {
    path: ROUTER_PATH.ADMIN,
    element: <PrivateRouter />,
    children: [
      {
        path: ROUTER_PATH.PRODUCTS,
        element: <Products />,
      },

      {
        path: ROUTER_PATH.PRODUCTS + ROUTER_PATH.CREATE,
        element: <ProductsCrud />,
      },
      {
        path: ROUTER_PATH.PRODUCTS + ROUTER_PATH.EDIT,
        element: <ProductsCrud />,
      },
      {
        path: ROUTER_PATH.PRODUCTS + ROUTER_PATH.EDITDIS,
        element: <ProductsCrud />,
      },

      //objects
      {
        path: ROUTER_PATH.OBJECTS,
        element: <Objects />,
      },

      {
        path: ROUTER_PATH.OBJECTS + ROUTER_PATH.CREATE,
        element: <ObjectsCrud />,
      },
      {
        path: ROUTER_PATH.OBJECTS + ROUTER_PATH.EDIT,
        element: <ObjectsCrud />,
      },
      //partners
      {
        path: ROUTER_PATH.PARTNERS,
        element: <Partners />,
      },

      {
        path: ROUTER_PATH.PARTNERS + ROUTER_PATH.CREATE,
        element: <PartnersCrud />,
      },
      {
        path: ROUTER_PATH.PARTNERS + ROUTER_PATH.EDIT,
        element: <PartnersCrud />,
      },
      //Review
      {
        path: ROUTER_PATH.REVIEW,
        element: <Review />,
      },
      //Orders
      {
        path: ROUTER_PATH.ORDERS,
        element: <Orders />,
      },
      //Question
      {
        path: ROUTER_PATH.QUESIONS,
        element: <Question />,
      },
      //BuyWhole
      {
        path: ROUTER_PATH.BUYWHOLE,
        element: <BuyWhole />,
      },
      //Branches  BranchsCrud
      {
        path: ROUTER_PATH.BRANCHS,
        element: <Branches />,
      },
      {
        path: ROUTER_PATH.BRANCHS + ROUTER_PATH.CREATE,
        element: <BranchsCrud />,
      },
      {
        path: ROUTER_PATH.BRANCHS + ROUTER_PATH.EDIT,
        element: <BranchsCrud />,
      },

      {
        path: "*",
        element: <h1>NotFound</h1>,
      },
    ],
  },
]);

import { IconLogout } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import {
  createStyles,
  Navbar,
  Group,
  getStylesRef,
  ScrollArea,
  Burger,
} from "@mantine/core";
import { SIDEBAR } from "../../constants/sidebar";
import { useViewportSize } from "@mantine/hooks";
import { Link, useLocation } from "react-router-dom";

export default function Sidebar({ opened, toggle, label }: any) {
  const { pathname } = useLocation();

  const { width } = useViewportSize();
  const useStyles = createStyles((theme) => ({
    navbar: {
      backgroundColor: theme.fn.variant({
        variant: "filled",
        color: theme.primaryColor,
      }).background,

      "@media (max-width:991px)": {
        transition: "all .3s ease",
        position: "fixed",
        top: 0,
        left: opened ? 0 : "-102%",
        width: "calc(100% + 1px)",
      },
    },

    header: {
      paddingBottom: theme.spacing.md,
      borderBottom: `var(--border-opacity)`,
      color: "#fff",
      width: "100%",
    },

    footer: {
      paddingTop: theme.spacing.md,
      marginTop: theme.spacing.md,
      borderTop: `var(--border-opacity)`,
    },

    link: {
      ...theme.fn.focusStyles(),
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      fontSize: theme.fontSizes.sm,
      color: theme.white,
      padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
      borderRadius: theme.radius.sm,
      fontWeight: 500,

      "&:hover": {
        backgroundColor: theme.fn.lighten(
          theme.fn.variant({ variant: "filled", color: theme.primaryColor })
            .background!,
          0.1
        ),
      },
    },

    linkIcon: {
      ref: getStylesRef("icon"),
      color: theme.white,
      opacity: 0.75,
      marginRight: theme.spacing.sm,
    },

    linkActive: {
      "&, &:hover": {
        backgroundColor: "var(--red-opacity)",
        [`& .${getStylesRef("icon")}`]: {
          opacity: 0.9,
        },
      },
    },

    linksInner: {
      paddingTop: theme.spacing.xl,
      paddingBottom: theme.spacing.xl,
    },

    links: {
      marginLeft: `calc(${theme.spacing.md} * -1)`,
      marginRight: `calc(${theme.spacing.md} * -1)`,
      Height: `calc(100vh - 500px)`,
    },
  }));

  const { classes, cx } = useStyles();
  const [active, setActive] = useState("");
  const links = SIDEBAR.map((item) => (
    <Link
      className={cx(classes.link, {
        [classes.linkActive]: item.label === active,
      })}
      key={item.label}
      onClick={() => {
        setActive(item.label);
      }}
      to={"/admin/" + item?.link}
    >
      <item.icon className={classes.linkIcon} stroke={1.5} />
      <span>{item.label}</span>
    </Link>
  ));

  useEffect(() => {
    const activeLb = SIDEBAR?.find((item: any) =>
      pathname?.includes(item?.link)
    )?.label;
    activeLb !== undefined && setActive(activeLb);
  }, []);
  return (
    <Navbar
      height={"100vh"}
      width={{ md: 300 }}
      p="md"
      className={classes.navbar}
    >
      <Navbar.Section>
        <Group className={classes.header}>
          <h1 style={{ textAlign: "center" }}>FINFIRE</h1>
          {width < 991 && (
            <Burger
              color="white"
              ml={"auto"}
              opened={opened}
              onClick={toggle}
              aria-label={label}
            />
          )}
        </Group>
      </Navbar.Section>

      <Navbar.Section grow className={classes.links} component={ScrollArea}>
        <div className={classes.linksInner}>{links}</div>
      </Navbar.Section>

      <Navbar.Section className={classes.footer}>
        <div
          className={classes.link}
          //   onClick={(event) => event.preventDefault()}
        >
          <IconLogout className={classes.linkIcon} stroke={1.5} />
          <span>Chiqish</span>
        </div>
      </Navbar.Section>
    </Navbar>
  );
}

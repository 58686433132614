import { IconArrowDownRight, IconArrowUpRight } from "@tabler/icons-react";
import useUsd from "../../../pages/products/hook/useUsd";
import { Wrapper } from "./style";

export default function Valuta() {
  const { usd } = useUsd();
  return (
    <>
      {usd && (
        <Wrapper>
          <p>{usd?.Date}</p>|
          <p>
            1 USD - <b>{usd?.Rate}</b> UZS
          </p>
          |
          <div className="counter">
            {usd?.Diff * 1 > 0 ? (
              <span className="green">
                <IconArrowUpRight size={12} strokeWidth={1} />
              </span>
            ) : (
              <span>
                <IconArrowDownRight size={12} strokeWidth={1} />
              </span>
            )}
            {usd?.Diff} uzs
          </div>
        </Wrapper>
      )}
    </>
  );
}

import type { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { InnerPaper, PagePaper } from "./style";

interface IHocPrivate {
  children: ReactNode;
}

export const HocPrivate = ({ children }: IHocPrivate) => {
  if (!localStorage.getItem("finfire-admin-token")) {
    return <Navigate to="/" />;
  }

  return (
    <PagePaper>
      <InnerPaper>{children}</InnerPaper>
    </PagePaper>
  );
};

import { notifications } from '@mantine/notifications'
import { QueryCache, QueryClient, MutationCache } from '@tanstack/react-query'

const mutationCache = new MutationCache({
	onError(error: any) {
		notifications.show({
			color: 'red',
			autoClose: 5000,
			withCloseButton: true,
			message: error.message,
		})
	},
})

const queryCache = new QueryCache({
	onError: (error: any) => {
		notifications.show({
			color: 'red',
			autoClose: 5000,
			withCloseButton: true,
			message: error.message,
		})
	},
})

export const queryClient = new QueryClient({
	queryCache,
	mutationCache,
	defaultOptions: {
		queries: {
			useErrorBoundary: true,
			refetchOnWindowFocus: false,
			retry(failureCount, error: any) {
				if (error.statusCode === 404) return false
				if (failureCount < 1) return true
				return false
			},
		},
	},
})

import { useQuery } from "@tanstack/react-query";
import React from "react";
import { request } from "../../../utils/axios-request";

export default function useUsd() {
  const { data: USD = undefined } = useQuery(
    ["USD"],
    () => request("admin/usd"),
    {
      select: (res) => {
        return res?.data?.data;
      },
      onSuccess: (res) => {
        return res;
      },
    }
  );
  return { usd: USD };
}

import type { MantineThemeOverride } from "@mantine/core";

export const theme = {
  fontFamily: "Outfit, sans-serif",
  colors: {
    brand: [
      "var(--red-6)",
      "var(--red-5)",
      "var(--red-4)",
      "var(--red-3)",
      "var(--red-2)",
      "var(--red-1)",
      "var(--red)",
      "var(--red-hover)",
      "var(--red-hover2)",
      "var(--red-hover3)",
    ],
  },
  primaryColor: "brand",
  components: {
    Input: {
      styles: {
        input: {
          height: "45px",
        },
      },
    },
    PasswordInput: {
      styles: {
        innerInput: {
          height: "45px",
        },
      },
    },
    Select: {
      styles: {
        input: {
          height: "45px",
        },
      },
    },
    Button: {
      styles: {
        root: {
          height: "45px",
        },
      },
    },
  },
} as MantineThemeOverride;

export const ROUTER_PATH = {
  NOT_FOUND: "*",
  LOGIN: "/",
  ADMIN: "admin",
  PRODUCTS: "products",
  BRANCHS: "branchs",
  PARTNERS: "partners",
  REVIEW: "review",
  ORDERS: "orders",
  OBJECTS: "objects",
  QUESIONS: "questions",
  BUYWHOLE: "buywhole",
  ID: "/:id",
  CREATE: "/create",
  EDIT: "/edit/:id",
  EDITDIS: "/edit-discount/:id",
};

import styled from "@emotion/styled";

export const Wrapper = styled("div")`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  font-size: 16px;
  gap: 6px;
  margin-left: auto;

  @media (max-width: 991px) {
    justify-content: flex-start;
    transform: translateY(-3px);
  }
  p {
    font-size: 13px;
    b {
      font-size: 16px;
    }
  }
  .counter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
    font-size: 13px;

    span {
      background-color: rgba(255, 0, 0, 0.1);
      border-radius: 4px;
      color: red;
      padding: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

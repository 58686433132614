import styled from "@emotion/styled";

export const Container = styled("div")`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  overflow: hidden;
`;

export const PageContainer = styled("div")`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  overflow: hidden;
  background: transparent;
  flex-direction: column;
  padding: 24px;
  @media (max-width: 1400px) {
    padding: 16px;
  }
`;
export const PagePaper = styled("div")`
  padding: 10px 8px;
  background-color: var(--background);
  border-radius: 12px;
`;
export const InnerPaper = styled("div")`
  width: 100%;
  max-height: calc(100vh - 166px);
  min-height: calc(100vh - 180px);
  padding: 6px 12px;
  background-color: var(--background);
  border-radius: 12px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: var(--backgorund-1);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: var(--red);
  }
`;

export const WrapLoader = styled("div")`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

import { Loader, MantineProvider } from "@mantine/core";
import { QueryClientProvider } from "@tanstack/react-query";
import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { queryClient } from "./utils/react-query";
import { theme } from "./utils/theme";
import { Notifications } from "@mantine/notifications";
import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import "./global.css";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
      <Suspense fallback={<Loader variant="bars" />}>
        <QueryClientProvider client={queryClient}>
          <Notifications />
          <RouterProvider router={router} />
        </QueryClientProvider>
      </Suspense>
    </MantineProvider>
  </>
);

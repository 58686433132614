import { Burger } from "@mantine/core";
import { Wrapper } from "./style";
import { useViewportSize } from "@mantine/hooks";
import { useLocation } from "react-router-dom";
import { SIDEBAR } from "../../constants/sidebar";
import Valuta from "./valuta";

export default function Header({ opened, toggle, label }: any) {
  const { width } = useViewportSize();
  const { pathname } = useLocation();
  return (
    <Wrapper>
      <h2>
        {SIDEBAR?.find((item: any) => pathname?.includes(item?.link))?.label}
      </h2>
      {width < 991 && (
        <Burger opened={opened} onClick={toggle} aria-label={label} />
      )}
      <Valuta />
    </Wrapper>
  );
}

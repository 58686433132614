import styled from "@emotion/styled";

export const Wrapper = styled("div")`
  width: 100%;
  border-radius: 12px;
  margin-bottom: 24px;
  padding: 20px;
  background-color: var(--background);
  max-height: 74px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1 {
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-decoration: none;
  }
  @media (max-width: 991px) {
    flex-wrap: wrap;
    position: relative;
  }
`;
